import * as React from 'react';
import ingestIcon from './assets/VCF_value_prop1.png';
import visualizeIcon from './assets/VCF_value_prop3.png';
import analyzeScaleIcon from './assets/VCF_value_prop2.png';
import CloudAnalysis from '../../common/CloudAnalysis';

const items = [
  {
    title: 'Performant and cost-effective ingest ',
    description:
      'Ingest data in parallel for as low as $0.01/per sample, for millions of samples. Rapidly add samples and refresh cohort summary statistics for billions of variants.',
    icon: ingestIcon,
  },
  {
    title: 'Genomic analysis at extreme scale',
    description:
      'Run scalable pipelines without complex infrastructure hassles. Perform dynamic queries that integrate genome, phenome and clinical EHR data — all on a single platform.',
    icon: analyzeScaleIcon,
  },
  {
    title: 'Governed, reproducible science',
    description:
      'Deliver auditable and governed access to genomic datasets and code, enabling efficient collaboration with researchers and downstream partner organizations.',
    icon: visualizeIcon,
  },
];

const VCFAnalysis: React.FC = () => <CloudAnalysis variant="purple" title="Variant data management made simple" items={items} />;

export default VCFAnalysis;
