import React from 'react';
import links from '@constants/links';
import Resources from '../../common/Resources';
import bookIcon from './assets/Book.svg';
import videoIcon from './assets/video.svg';
import tutorialIcon from './assets/Grad-hat.svg';

const items = [
  {
    title: 'Documentation',
    text: 'Have a technical question? Check out our comprehensive documentation, learn about our genomics integrations and dig into API reference materials.',
    linkText: 'Read the docs',
    link: links.genomics,
    icon: bookIcon,
  },
  {
    title: 'Webinar',
    text: 'Watch the replay! Aaron Wolen, Senior Software Engineer, presented code examples, from data modeling to sharing access to multi-TB variant datasets.',
    linkText: 'Watch the video',
    link: 'https://tiledb.com/blog/population-genomics-is-a-data-management-problem-2021-11-17',
    icon: videoIcon,
  },
  {
    title: 'Tutorial',
    text: 'Ready to get started? Jump right in with the TileDB VCF Quickstart tutorial on TileDB Cloud using Jupyter notebooks directly through its console.',
    linkText: 'Go to tutorial',
    link: 'https://cloud.tiledb.com/notebooks/details/TileDB-Inc/tutorial_tiledbvcf_basics/preview',
    icon: tutorialIcon,
  },
];

const VCFResources: React.FC = () => {
  return <Resources items={items} />;
};

export default VCFResources;
