import * as React from 'react';
import links from '@constants/links';
import ServiceCombination from '../../common/ServiceCombination';

const items = [
  {
    title: 'Arrays',
    subtitle: 'VCF',
    text: 'The open-source library for managing variant data as arrays.',
    link: links.vcfLib,
    isExternalLink: true,
  },
  {
    title: 'Database',
    subtitle: 'CLOUD',
    text: 'An advanced governance and computational platform for variant data.',
    link: links.cloud,
  },
];

const VCFServiceCombination: React.FC = () => <ServiceCombination variant="purple" title="Secret Sauce" items={items} />;

export default VCFServiceCombination;
