import * as React from 'react';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Footer from '@components/Footer';
import Container from '@components/Container';
import SectionStayConnectedForBlog from '@page-components/Blog/SectionStayConnectedForBlog';
import Related from '@components/IndustryDataType/Related';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import Jumbotron from '../common/Jumbotron';
import metaImage from './assets/VCF_page_thumbnail.jpg';
import background from './assets/vcf_hero.jpg';
import VCFAnalysis from './VCFAnalysis';
import VCFCapabilities from './VCFCapabilities';
import VCFResources from './VCFResources';
import VCFServiceCombination from './VCFServiceCombination';

type Props = {};

const VCFPage: React.FC<Props> = () => {
  return (
    <IndexLayout
      pageName="lidar"
      helmet={{
        title: 'TileDB - Population genomics data management made fast, cost-efficient and practical',
        description: 'Analyze and share enormous variant datasets with TileDB Cloud.',
        shareImage: {
          url: metaImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main VCFPage">
        <Jumbotron
          variant="purple"
          title="Population genomics data management made fast, cost-efficient and practical"
          subtitle="Analyze and share enormous variant datasets with TileDB Cloud."
          backgroundImage={background}
          withGradient
        />
        <VCFAnalysis />
        <VCFCapabilities />
        <VCFServiceCombination />
        <VCFResources />
        <Related items={[industries.healthcare, dataTypes.singleCell]} />
        <Container>
          <SectionStayConnectedForBlog />
        </Container>
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default VCFPage;
