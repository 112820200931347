import React from 'react';
import Capabilities from '../../common/Capabilities';
import modelVCFIcon from './assets/VCF_analysis-ready_arrays.png';
import dataSetsParallelIcon from './assets/parallel_ingestion.png';
import solveNIcon from './assets/nplus1_problem.png';
import sliceIcon from './assets/slice_and_filter.png';
import governIcon from './assets/data_governance.png';
import clusterHassleIcon from './assets/cluster_hassles.png';
import interoperabilityIcon from './assets/interoperability.png';
import collaborationIcon from './assets/collaboration.png';

const capabilities = [
  {
    title: 'Model VCF data as analysis-ready arrays ',
    description:
      'Replace collections of VCF files with sparse multi-dimensional arrays. Manage variant data in a cloud-native format, and store any number of samples in a compressed and lossless manner for tremendous storage savings.',
    icon: modelVCFIcon,
  },
  {
    title: 'Ingest massive datasets in parallel',
    description:
      'TileDB supports parallel writes enabling ingestion to be scaled out across multiple nodes with zero inter-process communication. VCF parsing is split across multiple workers and remote files are downloaded asynchronously.',
    icon: dataSetsParallelIcon,
  },
  {
    title: 'Solve the N+1 problem ',
    description:
      'TileDB arrays are updatable with arbitrary metadata and versioning built-in. Rapidly add new samples, scaling storage linearly. Avoid unnecessary storage inflation by maintaining the natively sparse format of the variant data.',
    icon: solveNIcon,
  },
  {
    title: 'Slice and filter in real time ',
    description:
      'Efficiently explore any VCF field such as chromosome, position or sample. Leverage a dedicated C++ library that is optimized for querying variant records by genomic regions across arbitrary numbers of samples.',
    icon: sliceIcon,
  },
  {
    title: 'Securely govern your data ',
    description:
      'TileDB Cloud is designed from the ground-up with strict governance and auditing guarantees. Access control on each dataset enables easy, auditable sharing for individual users or entire organizations. No more expensive data rehosting costs and cumbersome downloads.',
    icon: governIcon,
  },
  {
    title: 'Eliminate cluster hassles',
    description:
      "TileDB's serverless design allows you to scale workloads without manually spinning up clusters. Run pipelines, perform genome-wide analyses and calculate sample- or variant-level metrics on TileDB Cloud, right from your browser.",
    icon: clusterHassleIcon,
  },
  {
    title: 'Interoperate with popular tools',
    description:
      'Access variant data with a variety of APIs and integrations, including Python, R, Java, Spark and Hail. You can also losslessly export to the original VCF files, as well as to combined VCF files, providing compatibility with popular tools for genomic analysis.',
    icon: interoperabilityIcon,
  },
  {
    title: 'Collaborate easily',
    description:
      'Share large datasets, notebooks, code, even dashboards, within and outside your organization. Eliminate downloads and any kind of data movement. Log and audit every action on your data and code assets.',
    icon: collaborationIcon,
  },
];

const VCFCapabilities = () => {
  return <Capabilities variant="purple" capabilities={capabilities} title="We reimagined genomics data management with TileDB Cloud" />;
};

export default VCFCapabilities;
